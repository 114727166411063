import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

function Commands() {
  return (
    <>
      <Helmet>
        <title>Bot | Commands</title>
        <meta name="description" content="bot commands" />
      </Helmet>

      <Div>Soon</Div>
    </>
  );
}

export default Commands;

const Div = styled.div`
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  p {
    color: #5b5a64;
  }
`;
