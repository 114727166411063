import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  AiOutlineGithub,
  FaDiscord,
  HiOutlineMail,
  FiTwitter,
  // FaInstagram,
  // FaSpotify,
} from "react-icons/all";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <Div>
      <Helmet>
        <title>Autistic | Home</title>
        <meta
          name="description"
          content="Hi, I'm a software developer and designer."
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8757649275743257"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <h1>Autistic</h1>
        <p>Hi, I'm a software developer and designer.</p>
        <SocialMedia>
          <SocialMediaLink aria-label="Twitter" target="_self" to="/twitter">
            <FiTwitter />
          </SocialMediaLink>

          <SocialMediaLink aria-label="Discord" target="_self" to="/discord">
            <FaDiscord />
          </SocialMediaLink>

          <SocialMediaLink aria-label="Github" target="_self" to="/github">
            <AiOutlineGithub />
          </SocialMediaLink>

          {/* <SocialMediaLink aria-label="Instagram" target="_self" to="/inst">
            <FaInstagram />
          </SocialMediaLink> */}

          <SocialMediaLink aria-label="Mail" target="_self" to="/mail">
            <HiOutlineMail />
          </SocialMediaLink>

          {/* <SocialMediaLink aria-label="Spotify" target="_self" to="/spotify">
            <FaSpotify />
          </SocialMediaLink> */}
        </SocialMedia>
      </div>
    </Div>
  );
}

export default Home;

const Div = styled.div`
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  p {
    color: #5b5a64;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SocialMediaLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  justify-content: space-between;
  padding: 14px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: #7ea3cc;
  }
`;
