import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Commands from "./components/Commands";
import Home from "./components/Home";
import Loading from "./utils/Loading";
import {
  Twitter,
  Discord,
  Github,
  Instagram,
  RickRoll,
  MusicBot,
  Vote,
} from "./utils/Social";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/commands" component={Commands} exact />
      {/* <Route path="/projects" component={Commands} exact /> */}

      {/* Social */}
      <Route path="/twitter" component={Twitter} exact />
      <Route path="/discord" component={Discord} exact />
      <Route path="/github" component={Github} exact />
      <Route path="/inst" component={Instagram} exact />
      <Route path="/mail" component={RickRoll} exact />

      <Route path="/twitch/files" component={RickRoll} exact />

      <Route path="/MusicBot" component={MusicBot} exact />
      <Route path="/Vote" component={Vote} exact />

      <Route path="/test" component={Loading} exact />
      {/* 404 */}
      {/* <Route component={} /> */}
    </Switch>
  );
}

export default App;
