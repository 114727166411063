import { Helmet } from "react-helmet";

const Twitter = () => {
  return window.location.assign(
    "https://twitter.com/intent/follow?screen_name=Autistic1n"
  );
};
const Discord = () => {
  return window.location.assign("https://discord.com/users/659729856434536448");
};
const Github = () => {
  return window.location.assign("https://github.com/iAutistic");
};
const Instagram = () => {
  return window.location.assign("https://instagram.com/autistic1n/");
};
const RickRoll = () => {
  return window.location.assign("https://youtu.be/dQw4w9WgXcQ");
};

const MusicBot = () => {
  return (
    <>
      <Helmet>
        <title>MusicBot 🎶</title>
        <meta
          name="description"
          content="The easiest way to play music in your Discord server 🎶. Supports Spotify, YouTube, Soundcloud and more!"
        />
      </Helmet>
      {window.location.assign(
        "https://discord.com/oauth2/authorize?client_id=478287039016468491&scope=bot&permissions=70642768&guild_id=0"
      )}
    </>
  );
};
const Vote = () => {
  return (
    <>{window.location.assign("https://top.gg/bot/478287039016468491/vote")}</>
  );
};

export { Twitter, Discord, Github, Instagram, RickRoll, MusicBot, Vote };
